export const PAGE_TITLE = 'Products';
export const PAGE_SINGLE_TITLE = 'Product';
export const LINK_URL = 'products';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
    slug: true,
  },

  product_category: {
    type: 'related',
    required: true,
    title: 'Product Category',
    field: 'name',
  },

  regular_price: {
    type: 'string',
    required: false,
    title: 'Regular Price',
    inputType: 'number',
  },
  sale_price: {
    type: 'string',
    required: false,
    title: 'Sale Price',
    inputType: 'number',
  },
  description: {
    type: 'html',
    required: false,
    title: 'Description',
  },
  media: {
    type: 'gallery',
    required: false,
    title: 'Media',
  },
  buy_link: {
    type: 'string',
    required: false,
    title: 'Buy Link',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  {
    name: 'Product Category',
    value: 'product_category',
    related: true,
    field: 'name',
  },
  {
    name: 'Regular Price',
    value: 'regular_price',
  },
  {
    name: 'Sale Price',
    value: 'sale_price',
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'product_category',
    field: 'product_category',
    label: 'Product Category',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
