import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import Spinner from '../../components/layout/Spinner';
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from '../../shared/enums/ratings_enum';
import SingleView from '../../components/common/SingleView';
import { useSingleRating } from '../../shared/hooks/UseRating';
import { useUpdateNotification } from '../../shared/hooks/UseNotification';
import * as qs from 'qs';

const ViewRating = ({ match }) => {
  const queryParams = qs.parse(window.location.search.replace('?', ''));

  const [data] = useSingleRating(match.params.id);
  const { rating_loading, rating } = data;
  const { updateNotificationFunction } = useUpdateNotification();
  useEffect(() => {
    if (queryParams.notification) {
      updateNotificationFunction(queryParams.notification, {});
    }
  }, [queryParams.notification]);

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='View'
        />
        {!rating_loading ? (
          rating && (
            <SingleView
              data={rating}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={rating._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewRating;
