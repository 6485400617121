export const PAGE_TITLE = 'Medias';
export const PAGE_SINGLE_TITLE = 'Media';
export const LINK_URL = 'medias';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },

  category: {
    type: 'related',
    required: true,
    title: 'Category',
    inputType: 'text',
    field: 'name',
  },
  poster_image: {
    type: 'file',
    required: false,
    title: 'Poster Image',
  },
  released_year: {
    type: 'string',
    required: false,
    title: 'Released Year',
    inputType: 'text',
  },
  runtime: {
    type: 'string',
    required: false,
    title: 'Runtime',
    inputType: 'text',
  },
  about_media: {
    type: 'html',
    required: false,
    title: 'About Media',
    inputType: 'text',
  },

  short_description: {
    type: 'html',
    required: false,
    title: 'Short Description',
    inputType: 'text',
  },
  // casts: {
  //   type: 'related',
  //   required: false,
  //   title: 'Casts',
  //   inputType: 'text',
  //   multiple: true,
  //   field: 'name',
  // },
  director: {
    type: 'related-2',
    required: false,
    title: 'Director',
    inputType: 'text',
    multiple: true,
    field: 'name',
  },
  producers: {
    type: 'related-2',
    required: false,
    title: 'Producer',
    inputType: 'text',
    field: 'name',
    multiple: true,
  },
  writers: {
    type: 'related-2',
    required: false,
    title: 'Writers',
    inputType: 'text',
    multiple: true,
    field: 'name',
  },
  actors: {
    type: 'related-2',
    required: false,
    title: 'Actor',
    inputType: 'text',
    multiple: true,
    field: 'name',
  },
  story: {
    type: 'html',
    required: false,
    title: 'Story',
  },
  photos: {
    type: 'gallery',
    required: false,
    title: 'Photos',
  },
  videos: {
    type: 'gallery',
    required: false,
    title: 'Videos',
  },
  link_to_watch: {
    type: 'string',
    required: false,
    title: 'Links to Watch',
  },
  genre: {
    type: 'related-2',
    required: true,
    title: 'Genre',
    inputType: 'text',
    multiple: true,
    field: 'name',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Poster', value: 'poster_image', image: true },
  { name: 'Category', value: 'category', related: true, field: 'name' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'category',
    field: 'category',
    label: 'Category',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'genre',
    field: 'genre',
    label: 'Genre',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'casts',
    field: 'casts',
    label: 'Cast',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
