export const PAGE_TITLE = 'Ratings';
export const PAGE_SINGLE_TITLE = 'Rating';
export const LINK_URL = 'ratings';
export const inputFields = {
  rating: {
    type: 'string',
    required: true,
    title: 'rating',
    inputType: 'Number',
  },
  description: {
    type: 'html',
    required: true,
    title: 'Description',
    inputType: 'text',
  },
  is_approved: {
    type: 'checkbox',
    required: false,
    title: 'Is Approved?',
    inputType: 'text',
  },
  user: {
    type: 'related',
    required: true,
    title: 'User',
    inputType: 'text',
    field: 'name',
  },
  media: {
    type: 'related',
    required: false,
    title: 'Media',
    field: 'name',
  },
  product: {
    type: 'related',
    required: false,
    title: 'Product',
    field: 'name',
  },
};
export const initialValues = {
  rating: '',
};

export const view_all_table = [
  { name: 'Rating', value: 'rating' },
  { name: 'Is Approved', value: 'is_approved', boolean: true },
  { name: 'Media', value: 'media', related: true, field: 'name' },
  { name: 'Product', value: 'product', related: true, field: 'name' },
  { name: 'User', value: 'user', related: true, field: 'name' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'media',
    field: 'media',
    label: 'Media',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'product',
    field: 'product',
    label: 'Product',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'user',
    field: 'user',
    label: 'User',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'is_approved',
    field: 'is_approved',
    label: 'is Approve',
    type: 'select',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
    options: ['true', 'false'],
  },
];
