import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addMedia,
  getMedias,
  getMedia,
  editMedia,
  deleteMedia,
  getAllMedias,
  addBulkRatings,
} from '../../store/actions/media_action';
import _debounce from 'lodash/debounce';
import { useAllCategorys } from './UseCategory';
import { useSelectAllCast } from './UseCast';
import { useSelectAllGenre } from './UseGenre';

// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllMedias = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteMedia(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getMedias({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleMedia = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  useEffect(() => {
    dispatch(getMedia(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateMedia = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  const addData = async (data) => {
    await dispatch(addMedia(data));
  };
  return [data, addData];
};

export const useBulkAddRatings = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  const addData = async (data) => {
    await dispatch(addBulkRatings(data));
  };
  return [data, addData];
};
export const useUpdateMedia = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.media);
  const updateData = async (id, data) => {
    await dispatch(editMedia(id, data));
  };
  return [updateData];
};

export const useSelectAllMedia = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.media);
  useEffect(() => {
    dispatch(getAllMedias({ term: 'name', value }));
  }, [term, value]);
  return [data, setValue];
};

export const useGetDropdownOptions = () => {
  const [category] = useAllCategorys();
  const [cast, setCastValue] = useSelectAllCast();
  const [director, setDirectorValue] = useSelectAllCast();
  const [producer, setProducerValue] = useSelectAllCast();
  const [writer, setWriterValue] = useSelectAllCast();
  const [genre] = useSelectAllGenre();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (category && category.categorys) {
      const newData = category.categorys.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, category: newData });
    }
  }, [category]);
  useEffect(() => {
    if (genre && genre.all_genres && genre.all_genres.genres) {
      const newData = genre.all_genres.genres.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, genre: newData });
    }
  }, [genre]);
  useEffect(() => {
    if (cast) {
      const newData = cast.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        actors: newData,
      });
    }
  }, [cast]);
  useEffect(() => {
    if (director) {
      const newData = director.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        director: newData,
      });
    }
  }, [director]);
  useEffect(() => {
    if (producer) {
      const newData = producer.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        producers: newData,
      });
    }
  }, [producer]);
  useEffect(() => {
    if (writer) {
      const newData = writer.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        writers: newData,
      });
    }
  }, [writer]);

  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'actors') {
      // await setCategorySearchField('actor');
      await setCastValue(inputValue);
      callback(dropdownOptions.actors);
    }
    if (field == 'director') {
      // await setCategorySearchField('actor');
      await setDirectorValue(inputValue);
      callback(dropdownOptions.director);
    }
    if (field == 'producers') {
      // await setCategorySearchField('actor');
      await setProducerValue(inputValue);
      callback(dropdownOptions.producers);
    }
    if (field == 'writers') {
      // await setCategorySearchField('actor');
      await setWriterValue(inputValue);
      callback(dropdownOptions.writers);
    }
  };

  return [dropdownOptions, loadOptions];
};
