export const PAGE_TITLE = 'Customers';
export const PAGE_SINGLE_TITLE = 'Customer';
export const LINK_URL = 'customers';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },

  email: {
    type: 'string',
    required: true,
    title: 'Email',
    inputType: 'text',
  },
  country_code: {
    type: 'string',
    required: true,
    title: 'Country Code',
    inputType: 'text',
  },
  phone: {
    type: 'string',
    required: true,
    title: 'Phone',
    inputType: 'text',
  },
  password: {
    type: 'string',
    required: true,
    title: 'Password',
    inputType: 'password',
    hideOnView: true,
  },
  role: {
    type: 'select',
    required: true,
    title: 'Role',
    options: ['ADMIN', 'USER'],
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Email', value: 'email' },
  { name: 'User Role', value: 'role' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'email',
    field: 'email',
    label: 'Email',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'role',
    field: 'role',
    label: 'Role',
    type: 'select',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
    options: ['ADMIN', 'USER'],
  },
];
