import {
  GET_CASTS_STATED,
  GET_CASTS,
  GET_CASTS_ENDED,
  ADD_CAST_STATED,
  ADD_CAST,
  ADD_CAST_ENDED,
  EDIT_CAST_STATED,
  EDIT_CAST,
  EDIT_CAST_ENDED,
  GET_CAST_STATED,
  GET_CAST,
  GET_CAST_ENDED,
  GET_ALL_CASTS_STATED,
  GET_ALL_CASTS,
  GET_ALL_CASTS_ENDED,
} from '../types/cast_type';

const initialState = {
  casts_loading: true,
  casts: null,
  page: null,
  pages: null,
  total_casts: 0,

  cast: null,
  cast_loading: null,

  loading: true,

  cast_message: null,
  all_casts: null,
  all_casts_loading: null,
  add_cast_loading: true,
  edit_cast_loading: true,
};

export const cast_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CASTS_STATED:
      return {
        ...state,
        casts: null,
        pages: null,
        page: null,
        total_casts: 0,
        casts_loading: true,
      };
    case GET_CASTS:
      return {
        ...state,
        casts: payload.casts,
        pages: payload.pages,
        page: payload.page,
        total_casts: payload.total_casts,
      };
    case GET_CASTS_ENDED:
      return {
        ...state,
        casts_loading: false,
      };
    case GET_ALL_CASTS_STATED:
      return {
        ...state,
        all_casts_loading: true,
        all_casts: null,
      };
    case GET_ALL_CASTS:
      return {
        ...state,
        all_casts: payload,
      };
    case GET_ALL_CASTS_ENDED:
      return {
        ...state,
        all_casts_loading: false,
      };

    case ADD_CAST_STATED:
      return {
        ...state,
        cast_message: null,
        add_cast_loading: true,
      };
    case ADD_CAST:
      return {
        ...state,
        cast_message: payload,
      };
    case ADD_CAST_ENDED:
      return {
        ...state,
        add_cast_loading: false,
      };
    case GET_CAST_STATED:
      return {
        ...state,
        cast: null,
        cast_loading: true,
      };
    case GET_CAST:
      return {
        ...state,
        cast: payload,
      };
    case GET_CAST_ENDED:
      return {
        ...state,
        cast_loading: false,
      };
    case EDIT_CAST_STATED:
      return {
        ...state,
        cast_message: null,
        edit_cast_loading: true,
      };
    case EDIT_CAST:
      return {
        ...state,
        cast_message: payload,
      };
    case EDIT_CAST_ENDED:
      return {
        ...state,
        edit_cast_loading: false,
      };

    default:
      return state;
  }
};
