export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    roles: ['ADMIN'],
  },
  {
    label: 'Media',
    link: '#',
    roles: ['ADMIN'],
    menu: [
      {
        label: 'Category',
        link: '/categorys',
        roles: ['ADMIN'],
      },
      {
        label: 'Genre',
        link: '/genres',
        roles: ['ADMIN'],
      },

      {
        label: 'Casts',
        link: '/casts',
        roles: ['ADMIN'],
      },

      {
        label: 'Media',
        link: '/medias',
        roles: ['ADMIN'],
      },
      {
        label: 'Ratings',
        link: '/ratings',
        roles: ['ADMIN'],
      },
    ],
  },
  {
    label: 'Products',
    link: '#',
    roles: ['ADMIN'],
    menu: [
      {
        label: 'Product Category',
        link: '/productcategorys',
        roles: ['ADMIN'],
      },
      {
        label: 'Products',
        link: '/products',
        roles: ['ADMIN'],
      },
    ],
  },
  {
    label: 'Blogs',
    link: '/blogs',
    roles: ['ADMIN'],
  },
  {
    label: 'Banners',
    link: '/banners',
    roles: ['ADMIN'],
  },
  {
    label: 'Contacts',
    link: '#',
    roles: ['ADMIN'],
    menu: [
      {
        label: 'Contacts',
        link: '/contacts',
        roles: ['ADMIN'],
      },
      {
        label: 'Newsletters',
        link: '/newsletters',
        roles: ['ADMIN'],
      },
      {
        label: 'Users',
        link: '/customers',
        roles: ['ADMIN'],
      },
    ],
  },

  // {
  //   label: 'Menu',
  //   link: '/menus',
  //   roles: ['ADMIN'],
  // },
  // {
  //   label: 'Settings',
  //   // link: "/homepages",
  //   link: '#',
  //   roles: ['ADMIN'],
  //   menu: [
  //     {
  //       label: 'Homepage',
  //       link: '/homepages',
  //       roles: ['ADMIN'],
  //     },
  //   ],
  // },
];
