import {
  GET_GENRES_STATED,
  GET_GENRES,
  GET_GENRES_ENDED,
  ADD_GENRE_STATED,
  ADD_GENRE,
  ADD_GENRE_ENDED,
  EDIT_GENRE_STATED,
  EDIT_GENRE,
  EDIT_GENRE_ENDED,
  GET_GENRE_STATED,
  GET_GENRE,
  GET_GENRE_ENDED,
  GET_ALL_GENRES_STATED,
  GET_ALL_GENRES,
  GET_ALL_GENRES_ENDED,
} from '../types/genre_type';

const initialState = {
  genres_loading: true,
  genres: null,
  page: null,
  pages: null,
  total_genres: 0,

  genre: null,
  genre_loading: null,

  loading: true,

  genre_message: null,
  all_genres: null,
  all_genres_loading: null,
  add_genre_loading: true,
  edit_genre_loading: true,
};

export const genre_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GENRES_STATED:
      return {
        ...state,
        genres: null,
        pages: null,
        page: null,
        total_genres: 0,
        genres_loading: true,
      };
    case GET_GENRES:
      return {
        ...state,
        genres: payload.genres,
        pages: payload.pages,
        page: payload.page,
        total_genres: payload.total_genres,
      };
    case GET_GENRES_ENDED:
      return {
        ...state,
        genres_loading: false,
      };
    case GET_ALL_GENRES_STATED:
      return {
        ...state,
        all_genres_loading: true,
        all_genres: null,
      };
    case GET_ALL_GENRES:
      return {
        ...state,
        all_genres: payload,
      };
    case GET_ALL_GENRES_ENDED:
      return {
        ...state,
        all_genres_loading: false,
      };

    case ADD_GENRE_STATED:
      return {
        ...state,
        genre_message: null,
        add_genre_loading: true,
      };
    case ADD_GENRE:
      return {
        ...state,
        genre_message: payload,
      };
    case ADD_GENRE_ENDED:
      return {
        ...state,
        add_genre_loading: false,
      };
    case GET_GENRE_STATED:
      return {
        ...state,
        genre: null,
        genre_loading: true,
      };
    case GET_GENRE:
      return {
        ...state,
        genre: payload,
      };
    case GET_GENRE_ENDED:
      return {
        ...state,
        genre_loading: false,
      };
    case EDIT_GENRE_STATED:
      return {
        ...state,
        genre_message: null,
        edit_genre_loading: true,
      };
    case EDIT_GENRE:
      return {
        ...state,
        genre_message: payload,
      };
    case EDIT_GENRE_ENDED:
      return {
        ...state,
        edit_genre_loading: false,
      };

    default:
      return state;
  }
};
