export const PAGE_TITLE = 'Casts';
export const PAGE_SINGLE_TITLE = 'Cast';
export const LINK_URL = 'casts';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
  image: {
    type: 'file',
    required: false,
    title: 'Image',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Image', value: 'image', image: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
