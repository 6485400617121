import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCast,
  getCasts,
  getCast,
  editCast,
  deleteCast,
  getAllCasts,
} from '../../store/actions/cast_action';
import _debounce from 'lodash/debounce';
import api from '../../domain/api';
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllCasts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cast);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCast(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCasts({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleCast = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cast);
  useEffect(() => {
    dispatch(getCast(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCast = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cast);
  const addData = async (data) => {
    await dispatch(addCast(data));
  };
  return [data, addData];
};
export const useUpdateCast = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.cast);
  const updateData = async (id, data) => {
    await dispatch(editCast(id, data));
  };
  return [updateData];
};

export const useSelectAllCast = () => {
  const [value, setValue] = useState('');
  const [casts, setCasts] = useState(null);
  const getAllCasts = async ({ term, value }) => {
    const { data } = await api.get(`/casts/?search[name]=${value}`);
    setCasts(data.casts);
  };

  useEffect(() => {
    getAllCasts({ value });
  }, [value]);
  return [casts, setValue];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};
