import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addRating,
  getRatings,
  getRating,
  editRating,
  deleteRating,
  getAllRatings,
} from '../../store/actions/rating_action';
import _debounce from 'lodash/debounce';
import { useAllMedias } from './UseMedia';
import { useAllProducts } from './UseProduct';
import { useAllCustomers } from './UseCustomer';

// Get All Data
export const useAllRatings = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rating);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteRating(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getRatings({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleRating = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rating);
  useEffect(() => {
    dispatch(getRating(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateRating = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rating);
  const addData = async (data) => {
    await dispatch(addRating(data));
  };
  return [data, addData];
};
export const useUpdateRating = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.rating);
  const updateData = async (id, data) => {
    await dispatch(editRating(id, data));
  };
  return [updateData];
};

export const useSelectAllRating = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.rating);
  useEffect(() => {
    dispatch(getAllRatings({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [media] = useAllMedias();
  const [customer] = useAllCustomers();
  const [product] = useAllProducts();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (media && media.medias) {
      const newData = media.medias.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, media: newData });
    }
  }, [media]);
  useEffect(() => {
    if (customer && customer.customers) {
      const newData = customer.customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, user: newData });
    }
  }, [customer]);
  useEffect(() => {
    if (product && product.products) {
      const newData = product.products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, product: newData });
    }
  }, [product]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};
