import {
  GET_RATINGS_STATED,
  GET_RATINGS,
  GET_RATINGS_ENDED,
  ADD_RATING_STATED,
  ADD_RATING,
  ADD_RATING_ENDED,
  EDIT_RATING_STATED,
  EDIT_RATING,
  EDIT_RATING_ENDED,
  GET_RATING_STATED,
  GET_RATING,
  GET_RATING_ENDED,
  GET_ALL_RATINGS_STATED,
  GET_ALL_RATINGS,
  GET_ALL_RATINGS_ENDED,
} from '../types/rating_type';

const initialState = {
  ratings_loading: true,
  ratings: null,
  page: null,
  pages: null,
  total_ratings: 0,

  rating: null,
  rating_loading: null,

  loading: true,

  rating_message: null,
  all_ratings: null,
  all_ratings_loading: null,
  add_rating_loading: true,
  edit_rating_loading: true,
};

export const rating_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RATINGS_STATED:
      return {
        ...state,
        ratings: null,
        pages: null,
        page: null,
        total_ratings: 0,
        ratings_loading: true,
      };
    case GET_RATINGS:
      return {
        ...state,
        ratings: payload.ratings,
        pages: payload.pages,
        page: payload.page,
        total_ratings: payload.total_ratings,
      };
    case GET_RATINGS_ENDED:
      return {
        ...state,
        ratings_loading: false,
      };
    case GET_ALL_RATINGS_STATED:
      return {
        ...state,
        all_ratings_loading: true,
        all_ratings: null,
      };
    case GET_ALL_RATINGS:
      return {
        ...state,
        all_ratings: payload,
      };
    case GET_ALL_RATINGS_ENDED:
      return {
        ...state,
        all_ratings_loading: false,
      };

    case ADD_RATING_STATED:
      return {
        ...state,
        rating_message: null,
        add_rating_loading: true,
      };
    case ADD_RATING:
      return {
        ...state,
        rating_message: payload,
      };
    case ADD_RATING_ENDED:
      return {
        ...state,
        add_rating_loading: false,
      };
    case GET_RATING_STATED:
      return {
        ...state,
        rating: null,
        rating_loading: true,
      };
    case GET_RATING:
      return {
        ...state,
        rating: payload,
      };
    case GET_RATING_ENDED:
      return {
        ...state,
        rating_loading: false,
      };
    case EDIT_RATING_STATED:
      return {
        ...state,
        rating_message: null,
        edit_rating_loading: true,
      };
    case EDIT_RATING:
      return {
        ...state,
        rating_message: payload,
      };
    case EDIT_RATING_ENDED:
      return {
        ...state,
        edit_rating_loading: false,
      };

    default:
      return state;
  }
};
