export const PAGE_TITLE = 'Categorys';
export const PAGE_SINGLE_TITLE = 'Category';
export const LINK_URL = 'categorys';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
    slug: true,
  },
  slug: {
    type: 'slug',
    required: true,
    title: 'Slug',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [{ name: 'Name', value: 'name' }];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
