import api from '../../domain/api';
import {
  GET_CASTS_STATED,
  GET_CASTS,
  GET_CASTS_ENDED,
  ADD_CAST_STATED,
  ADD_CAST,
  ADD_CAST_ENDED,
  EDIT_CAST_STATED,
  EDIT_CAST,
  EDIT_CAST_ENDED,
  GET_CAST_STATED,
  GET_CAST,
  GET_CAST_ENDED,
  GET_ALL_CASTS_STATED,
  GET_ALL_CASTS,
  GET_ALL_CASTS_ENDED,
} from '../types/cast_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addCast = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CAST_STATED,
    });
    const { data } = await api.post(`/casts/add`, formData);
    dispatch({
      type: ADD_CAST,
      payload: data,
    });
    dispatch({
      type: ADD_CAST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_CAST_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCasts =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CASTS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/casts?&${query}`);

      dispatch({
        type: GET_CASTS,
        payload: data,
      });
      dispatch({
        type: GET_CASTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CASTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCast = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CAST_STATED,
    });
    const { data } = await api.get(`/casts/${id}`);

    dispatch({
      type: GET_CAST,
      payload: data,
    });
    dispatch({
      type: GET_CAST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CAST_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCast = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CAST_STATED,
    });
    const { data } = await api.put(`/casts/${id}`, formData);
    dispatch({
      type: EDIT_CAST,
      payload: data,
    });
    dispatch({
      type: EDIT_CAST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_CAST_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCast = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/casts/${id}`);
    dispatch(setAlert('Cast Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCasts =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_CASTS_STATED,
      });
      const { data } = await api.get(`/casts/?search[name]=${value}`);

      dispatch({
        type: GET_ALL_CASTS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_CASTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_CASTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
