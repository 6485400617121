import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';

import PageNotFound from './containers/notfound/PageNotFound';
import Login from './containers/login/Login';
import Dashboard from './containers/dashboard/Dashboard';
import Profile from './containers/profile/Profile';

import AddContact from './containers/contacts/AddContact';
import AllContacts from './containers/contacts/AllContacts';
import ViewContact from './containers/contacts/ViewContact';
import EditContact from './containers/contacts/EditContact';

import AddNewsletter from './containers/newsletters/AddNewsletter';
import AllNewsletters from './containers/newsletters/AllNewsletters';
import ViewNewsletter from './containers/newsletters/ViewNewsletter';
import EditNewsletter from './containers/newsletters/EditNewsletter';

import AddProduct from './containers/products/AddProduct';
import AllProducts from './containers/products/AllProducts';
import ViewProduct from './containers/products/ViewProduct';
import EditProduct from './containers/products/EditProduct';

import AddCategory from './containers/categorys/AddCategory';
import AllCategorys from './containers/categorys/AllCategorys';
import ViewCategory from './containers/categorys/ViewCategory';
import EditCategory from './containers/categorys/EditCategory';

import AddBlog from './containers/blogs/AddBlog';
import AllBlogs from './containers/blogs/AllBlogs';
import ViewBlog from './containers/blogs/ViewBlog';
import EditBlog from './containers/blogs/EditBlog';

import Menu from './containers/menus-old/Menu';
import Settings from './containers/settings/Settings';

import AddMenu from './containers/menus/AddMenu';
import AllMenus from './containers/menus/AllMenus';
import ViewMenu from './containers/menus/ViewMenu';
import EditMenu from './containers/menus/EditMenu';

import AddHomepage from './containers/homepages/AddHomepage';
import AllHomepages from './containers/homepages/AllHomepages';
import ViewHomepage from './containers/homepages/ViewHomepage';
import EditHomepage from './containers/homepages/EditHomepage';

import AddProductcategory from './containers/productcategorys/AddProductcategory';
import AllProductcategorys from './containers/productcategorys/AllProductcategorys';
import ViewProductcategory from './containers/productcategorys/ViewProductcategory';
import EditProductcategory from './containers/productcategorys/EditProductcategory';

import AddGenre from './containers/genres/AddGenre';
import AllGenres from './containers/genres/AllGenres';
import ViewGenre from './containers/genres/ViewGenre';
import EditGenre from './containers/genres/EditGenre';

import AddCast from './containers/casts/AddCast';
import AllCasts from './containers/casts/AllCasts';
import ViewCast from './containers/casts/ViewCast';
import EditCast from './containers/casts/EditCast';

import AddMedia from './containers/medias/AddMedia';
import AllMedias from './containers/medias/AllMedias';
import ViewMedia from './containers/medias/ViewMedia';
import EditMedia from './containers/medias/EditMedia';
import BulkMediaRatings from './containers/medias/BulkMediaRatings';

import AddRating from './containers/ratings/AddRating';
import AllRatings from './containers/ratings/AllRatings';
import ViewRating from './containers/ratings/ViewRating';
import EditRating from './containers/ratings/EditRating';

import AddCustomer from './containers/customers/AddCustomer';
import AllCustomers from './containers/customers/AllCustomers';
import ViewCustomer from './containers/customers/ViewCustomer';
import EditCustomer from './containers/customers/EditCustomer';

import AddBanner from './containers/banners/AddBanner';
import AllBanners from './containers/banners/AllBanners';
import ViewBanner from './containers/banners/ViewBanner';
import EditBanner from './containers/banners/EditBanner';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path='/' component={Login} />
          <PrivateRoutes exact path='/dashboard' component={Dashboard} />
          <PrivateRoutes exact path='/profile' component={Profile} />

          <PrivateRoutes exact path='/genres' component={AllGenres} />
          <PrivateRoutes exact path='/genres/add' component={AddGenre} />
          <PrivateRoutes exact path='/genres/:id/view' component={ViewGenre} />
          <PrivateRoutes exact path='/genres/:id/edit' component={EditGenre} />

          <PrivateRoutes exact path='/casts' component={AllCasts} />
          <PrivateRoutes exact path='/casts/add' component={AddCast} />
          <PrivateRoutes exact path='/casts/:id/view' component={ViewCast} />
          <PrivateRoutes exact path='/casts/:id/edit' component={EditCast} />

          <PrivateRoutes exact path='/contacts' component={AllContacts} />
          <PrivateRoutes exact path='/contacts/add' component={AddContact} />
          <PrivateRoutes
            exact
            path='/contacts/:id/view'
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path='/contacts/:id/edit'
            component={EditContact}
          />

          <PrivateRoutes exact path='/newsletters' component={AllNewsletters} />
          <PrivateRoutes
            exact
            path='/newsletters/add'
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path='/newsletters/:id/view'
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path='/newsletters/:id/edit'
            component={EditNewsletter}
          />

          <PrivateRoutes exact path='/products' component={AllProducts} />
          <PrivateRoutes exact path='/products/add' component={AddProduct} />
          <PrivateRoutes
            exact
            path='/products/:id/view'
            component={ViewProduct}
          />
          <PrivateRoutes
            exact
            path='/products/:id/edit'
            component={EditProduct}
          />

          {/* <PrivateRoutes exact path="/pages" component={AllPages} />
          <PrivateRoutes exact path="/pages/add" component={AddPage} />
          <PrivateRoutes exact path="/pages/:id/view" component={ViewPage} />
          <PrivateRoutes exact path="/pages/:id/edit" component={EditPage} /> */}

          <PrivateRoutes exact path='/categorys' component={AllCategorys} />
          <PrivateRoutes exact path='/categorys/add' component={AddCategory} />
          <PrivateRoutes
            exact
            path='/categorys/:id/view'
            component={ViewCategory}
          />
          <PrivateRoutes
            exact
            path='/categorys/:id/edit'
            component={EditCategory}
          />

          <PrivateRoutes exact path='/blogs' component={AllBlogs} />
          <PrivateRoutes exact path='/blogs/add' component={AddBlog} />
          <PrivateRoutes exact path='/blogs/:id/view' component={ViewBlog} />
          <PrivateRoutes exact path='/blogs/:id/edit' component={EditBlog} />

          {/* <PrivateRoutes exact path="/menus/:slug/edit" component={Menu} />
          <PrivateRoutes exact path="/menus/add" component={Menu} />
          <PrivateRoutes exact path="/menus" component={Menu} /> */}

          <PrivateRoutes exact path='/menus' component={AllMenus} />
          <PrivateRoutes exact path='/menus/add' component={AddMenu} />
          <PrivateRoutes exact path='/menus/:id/view' component={ViewMenu} />
          <PrivateRoutes exact path='/menus/:id/edit' component={EditMenu} />
          <PrivateRoutes exact path='/settings' component={Settings} />

          <PrivateRoutes exact path='/homepages' component={AllHomepages} />
          <PrivateRoutes exact path='/homepages/add' component={AddHomepage} />
          <PrivateRoutes
            exact
            path='/homepages/:id/view'
            component={ViewHomepage}
          />
          <PrivateRoutes
            exact
            path='/homepages/:id/edit'
            component={EditHomepage}
          />

          <PrivateRoutes
            exact
            path='/productcategorys'
            component={AllProductcategorys}
          />
          <PrivateRoutes
            exact
            path='/productcategorys/add'
            component={AddProductcategory}
          />
          <PrivateRoutes
            exact
            path='/productcategorys/:id/view'
            component={ViewProductcategory}
          />
          <PrivateRoutes
            exact
            path='/productcategorys/:id/edit'
            component={EditProductcategory}
          />

          <PrivateRoutes exact path='/medias' component={AllMedias} />
          <PrivateRoutes
            exact
            path='/medias/bulk-upload'
            component={BulkMediaRatings}
          />
          <PrivateRoutes exact path='/medias/add' component={AddMedia} />
          <PrivateRoutes exact path='/medias/:id/view' component={ViewMedia} />
          <PrivateRoutes exact path='/medias/:id/edit' component={EditMedia} />

          <PrivateRoutes exact path='/ratings' component={AllRatings} />
          <PrivateRoutes exact path='/ratings/add' component={AddRating} />
          <PrivateRoutes
            exact
            path='/ratings/:id/view'
            component={ViewRating}
          />
          <PrivateRoutes
            exact
            path='/ratings/:id/edit'
            component={EditRating}
          />

          <PrivateRoutes exact path='/customers' component={AllCustomers} />
          <PrivateRoutes exact path='/customers/add' component={AddCustomer} />
          <PrivateRoutes
            exact
            path='/customers/:id/view'
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path='/customers/:id/edit'
            component={EditCustomer}
          />

          <PrivateRoutes exact path='/banners' component={AllBanners} />
          <PrivateRoutes exact path='/banners/add' component={AddBanner} />
          <PrivateRoutes
            exact
            path='/banners/:id/view'
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path='/banners/:id/edit'
            component={EditBanner}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
