import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import { useHistory } from 'react-router-dom';
import MediaForm from '../../components/medias/MediaForm';

import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/medias_enum';
import {
  useBulkAddRatings,
  useSelectAllMedia,
  useGetDropdownOptions,
} from '../../shared/hooks/UseMedia';
// import { useSelectAllMedia } from "../../shared/hooks/UseMedia";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  FileUpload,
  FileUploadCSV,
  SelectBox,
} from '../../components/Form/Form';
import AsyncSelect from 'react-select/async';

const BulkMediaRatings = ({}) => {
  let history = useHistory();
  const [media, addData] = useBulkAddRatings();
  const [data, setMediaValue] = useSelectAllMedia();
  const { all_medias } = data;
  const { add_media_loading } = media;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    await addData(values);
    // history.push(`/${LINK_URL}`);
  };

  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'media') {
      // await setCategorySearchField('actor');
      await setMediaValue(inputValue);
      const newData = all_medias.map((item) => {
        return { label: item.name, value: item._id };
      });
      callback(newData);
    }
  };

  const [dropdownOptions, setDropdownOptions] = useState([]);
  useEffect(() => {
    if (all_medias) {
      const newData = all_medias.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions(newData);
    }
  }, [all_medias]);

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={`Bulk Upload ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='Bulk Upload'
        />
      </div>
      <div className='container-fluid'>
        <div className='col-lg-9'>
          <div className='card'>
            <div className='card-header'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h4 className='card-title'> {PAGE_SINGLE_TITLE} </h4>
                  <p className='card-title-desc'>
                    Enter Details to add {PAGE_SINGLE_TITLE}
                  </p>
                </div>
                <div>
                  <a href='/Sample.csv'> Sample CSV </a>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <Formik
                initialValues={{
                  media: '',
                  csv: '',
                }}
                validationSchema={Yup.object({
                  media: Yup.string().required('Required'),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  submitFormClicked(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  console.log(formik);
                  return (
                    <Form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <AsyncSelect
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            loadOptions={(inputValue, callback) =>
                              loadOptions(inputValue, callback, 'media')
                            }
                            defaultOptions={dropdownOptions}
                            name={'media'}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              if (e) {
                                formik.setFieldValue('media', e.value);
                              }
                            }}
                          />
                        </div>
                        <div className='col-md-6'>
                          <FileUploadCSV
                            title='CSV'
                            item='csv'
                            formik={formik}
                            value={formik.values.csv}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <button type='submit' className='btn btn-success'>
                            {' '}
                            Save and Continue{' '}
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkMediaRatings;
