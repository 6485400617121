export const GET_CASTS_STATED = "GET_CASTS_STATED";
export const GET_CASTS = "GET_CASTS";
export const GET_CASTS_ENDED = "GET_CASTS_ENDED";
export const ADD_CAST_STATED = "ADD_CAST_STARTED";
export const ADD_CAST = "ADD_CAST";
export const ADD_CAST_ENDED = "ADD_CAST_ENDED";
export const EDIT_CAST_STATED = "EDIT_CAST_STATED";
export const EDIT_CAST = "EDIT_CAST";
export const EDIT_CAST_ENDED = "EDIT_CAST_ENDED";
export const GET_CAST = "GET_CAST";
export const GET_CAST_STATED = "GET_CAST_STATED";
export const GET_CAST_ENDED = "GET_CAST_ENDED";
export const RESET_CAST = "RESET_CAST";
export const ERROR_CAST = "ERROR_CAST";
export const GET_ALL_CASTS_STATED = "GET_ALL_CASTS_STATED";
export const GET_ALL_CASTS = "GET_ALL_CASTS";
export const GET_ALL_CASTS_ENDED = "GET_ALL_CASTS_ENDED";
