import React from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import { useAllDashboards } from '../../shared/hooks/UseDashboard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useAllNotifications } from '../../shared/hooks/UseNotification';
import renderHTML from 'react-render-html';

function Dashboard() {
  const [data] = useAllDashboards();
  const { dashboards, dashboards_loading } = data;
  const [notification_data] = useAllNotifications();
  const { notifications } = notification_data;
  console.log(dashboards);
  return (
    <div className='pace-done'>
      <Header />
      <div className='content-wrapper'>
        <FilterDateComponent link='/dashboard' />
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-8'>
            {!dashboards_loading ? (
              dashboards && (
                <>
                  <div className='row'>
                    <div class='col-md-4'>
                      <div class='dashboard-stat pending'>
                        <Link to={`/ratings`}>
                          <div class='report-title'>Total Ratings </div>
                        </Link>
                        <Link to={`/ratings`}>
                          <div class='report-stat'>
                            {' '}
                            {dashboards.total_ratings}{' '}
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div class='col-md-4'>
                      <div class='dashboard-stat pending'>
                        <Link to={`/products`}>
                          <div class='report-title'>Total Products </div>
                        </Link>
                        <Link to={`/products`}>
                          <div class='report-stat'>
                            {' '}
                            {dashboards.total_products}{' '}
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div class='col-md-4'>
                      <div class='dashboard-stat pending'>
                        <Link to={`/medias`}>
                          <div class='report-title'>Total Medias </div>
                        </Link>
                        <Link to={`/medias`}>
                          <div class='report-stat'>
                            {' '}
                            {dashboards.total_medias}{' '}
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* {dashboards.order_status_array &&
                      dashboards.order_status_array.map((item) => {
                        return (
                          <div class='col-md-3'>
                            <div class='dashboard-stat pending'>
                              <Link to={`/orders?exact[status]=${item._id}`}>
                                <div class='report-title'>{item._id} </div>
                              </Link>
                              <Link to={`/orders?exact[status]=${item._id}`}>
                                <div class='report-stat'>{item.count}</div>
                              </Link>
                            </div>
                          </div>
                        );
                      })} */}
                    {/* <div class='col-md-3'>
                      <div class='dashboard-stat pending'>
                        <Link to={`/orders`}>
                          <div class='report-title'>Order Amount </div>
                        </Link>
                        <Link to={`/orders`}>
                          <div class='report-stat'>
                            {' '}
                            ₹
                            {dashboards.order_total &&
                            dashboards.order_total.length > 0
                              ? dashboards.order_total[0] &&
                                dashboards.order_total[0].total
                              : 0}{' '}
                          </div>
                        </Link>
                      </div>
                    </div> */}
                    {/* {dashboards.order_total_stats &&
                      dashboards.order_total_stats.map((item) => {
                        return (
                          <div class='col-md-3'>
                            <div class='dashboard-stat pending'>
                              <Link to={`/orders?exact[status]=${item._id}`}>
                                <div class='report-title'>{item._id} </div>
                              </Link>
                              <Link to={`/orders?exact[status]=${item._id}`}>
                                <div class='report-stat'> ₹{item.total}</div>
                              </Link>
                            </div>
                          </div>
                        );
                      })} */}
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='card'>
                        <div className='card-header'> Top 100 Movies </div>
                        <div className='card-body'>
                          <table className='table table-striped'>
                            <thead>
                              <th> Sr No </th>
                              <th> Movie Name </th>
                              <th> Average Rating </th>
                            </thead>
                            <tbody>
                              {dashboards.top_100_highest_rated_medias_all &&
                                dashboards.top_100_highest_rated_medias_all.map(
                                  (item, index) => {
                                    return (
                                      <tr>
                                        <td> {index + 1} </td>
                                        <td>{item.media && item.media.name}</td>
                                        <td> {item.avg_rating} </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='card'>
                        <div className='card-header'> Top 100 Products </div>
                        <div className='card-body'>
                          <table className='table table-striped'>
                            <thead>
                              <th> Sr No </th>
                              <th> Product Name </th>
                              <th> Average Rating </th>
                            </thead>
                            <tbody>
                              {dashboards.top_100_highest_rated_products_all &&
                                dashboards.top_100_highest_rated_products_all.map(
                                  (item, index) => {
                                    return (
                                      <tr>
                                        <td> {index + 1} </td>
                                        <td>
                                          {item.media && item.product.name}
                                        </td>
                                        <td> {item.avg_rating} </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-md-12'>
                      <div className='card'>
                        <div className='card-body'>
                          <table className='table table-striped'>
                            <thead>
                              <tr>
                                <th>#Order No</th>
                                <th> Order Date </th>
                                <th> Order Amount </th>
                                <th> Order Status </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dashboards.orders &&
                                dashboards.orders.map((item) => {
                                  return (
                                    <tr>
                                      <td>{item.order_id}</td>
                                      <td>
                                        {' '}
                                        {moment(item.order_date).format(
                                          'DD-MMM-YYYY'
                                        )}{' '}
                                      </td>
                                      <td> {item.total_amount} </td>
                                      <td> {item.status} </td>
                                      <td>
                                        {' '}
                                        <Link to={`/orders/${item._id}/view`}>
                                          {' '}
                                          <i className='fa fa-eye'></i>{' '}
                                        </Link>{' '}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-title'> Notification </h4>
              </div>
              <div className='card-body'>
                {notifications &&
                  notifications.map((item) => {
                    return (
                      <div className='notification-area'>
                        <Link to={`${item.link}?notification=${item._id}`}>
                          {item.name}
                        </Link>

                        <div className='dropdown-divider' />
                      </div>
                    );
                  })}
                {/* {all_notifications &&
                  all_notifications.notifications &&
                  all_notifications.notifications.length == 0 && (
                    <p> No New Order </p>
                  )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
