import * as Yup from 'yup';

const generateRequired = ({ inputFields: inputFields }) => {
  if (inputFields) {
    if (Object.keys(inputFields)) {
      const newRequiredCheck = {};
      Object.keys(inputFields).map((item, index) => {
        if (inputFields[item].required) {
          // console.log(item);
          // newRequiredCheck[item] = Yup.string().required('Required');
          if (inputFields[item].multiple) {
            newRequiredCheck[item] = Yup.array().required('Required');
          } else {
            if (inputFields[item].type === 'gallery') {
              newRequiredCheck[item] = Yup.array().required('Required');
            } else {
              newRequiredCheck[item] = Yup.string().required('Required');
            }
          }
        }
      });
      return newRequiredCheck;
    }
  }
};

export default generateRequired;
