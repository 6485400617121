import React, { useEffect } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import Pagination from '../../components/common/Pagination';
import AddBtn from '../../components/common/AddBtn';
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from '../../shared/enums/ratings_enum';
import DataTable from '../../components/common/DataTable';
import {
  useAllRatings,
  useGetDropdownOptions,
} from '../../shared/hooks/UseRating';
import SidebarFilter from '../../components/common/SidebarFilter';
import ExportComponent from '../../components/common/ExportComponent';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import {
  UseDataForExcel,
  UseFilter,
  UseDataForExcelRatings,
} from '../../shared/hooks/UseExcel';
const AllRatings = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllRatings();
  const { ratings_loading, ratings, total_ratings, page, pages } = data;
  const [dropdownOptions, loadOptions] = useGetDropdownOptions();

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcelRatings();

  useEffect(() => {
    if (ratings) {
      convertToReadable(ratings, {
        ...inputFields,
        user: {
          type: 'related',
          required: true,
          title: 'User',
          inputType: 'text',
          field: 'phone',
        },
      });
    }
  }, [ratings]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Ratings', 'ratings');
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle='Dashboard'
          mainLinkUrl='/dashboard'
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />
        <FilterDateComponent link='/ratings' />

        <div className='container-fluid'>
          <div className='row'>
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
              />
            )}
            <div className='col-lg-10'>
              <div
                className='card'
                style={{ boxShadow: 'rgb(227 233 243) 0px 4px 22px' }}
              >
                <div className='card-body'>
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_ratings} records found
                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={ratings}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={ratings_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    />

                    <Pagination
                      data={ratings}
                      page={page}
                      pages={pages}
                      count={total_ratings}
                      setPage={setPageNumber}
                      loading={ratings_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRatings;
